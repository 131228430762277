
import { Vue, Component } from 'vue-property-decorator';

import EditPublicProfile from '@/components/EditPublicProfile.vue';

@Component({
  name: "Account",
  components: {
    EditPublicProfile,
  }
})
export default class extends Vue {
}
