
import { Vue, Component } from 'vue-property-decorator';
import { clone, pick } from 'ramda';

import { Auth, Teachers } from '@/store/modules';
import { User } from '@/store/auth';
import { Teacher } from '@/store/teachers';
import ImageUploadDialog from '@/components/ImageUploadDialog.vue';

@Component({
  name: "EditPublicProfile",
  components: {
    ImageUploadDialog,
  }
})
export default class extends Vue {
  requiredRule = (v: any) => !!v || 'This field is required';

  experienceUnits = [
    { text: 'Years', value: 'years' },
    { text: 'Months', value: 'months' }
  ];

  loading = true;
  error = '';

  loadingSave = false;
  errorSave = '';

  saved = false;
  
  model: Partial<Teacher> = {
    details: {}
  };

  get teacherId() {
    return (Auth.user as User).teacherId;
  }

  async refresh() {
    this.loading = true;
    try {
      const user = await Auth.getSelfInformation();
      if (!(user as User).teacher) {
        throw Error('No teacher');
      }

      this.model = clone((user as User).teacher as Teacher);
      
      if (!this.model.details) {
        this.model.details = {};
      }
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }

  mounted() {
    this.refresh();
  }

  async save() {
    this.loadingSave = true;
    this.errorSave = '';
    try {
      if(!(this.$refs as any).form.validate()) {
        throw new Error('Please correct highlighted problems');
      }

      const toUpdate: Partial<User> = pick(['name', 'businessName', 'about', 'details', 'avatarUrl'], this.model);
      const updated = await Teachers.updateTeacher(toUpdate);
      this.$emit('save', updated);
      this.saved = true;
      this.$router.push(`/teachers/${this.teacherId}?saved=true`)
    } catch (err) {
      this.errorSave = err.message;
    }
    this.loadingSave = false;
  }
}
